/* Miscellanoeous */
body,
html {
  overflow-x: hidden;
  padding-right: 0; // resets padding right added by Bootstrap modal
}

body {
  background: $content-bg;
}

*:-moz-full-screen,
*:-webkit-full-screen,
*:fullscreen *:-ms-fullscreen {
  overflow: auto;
}

.page-body-wrapper {
  min-height: calc(100vh - #{$navbar-height});
  padding-top: $navbar-height;
  @include display-flex();
  @include flex-direction(row);
  padding-left: 0;
  padding-right: 0;

  &.full-page-wrapper {
    min-height: 100vh;
    padding-top: 0;
    .main-panel {
      width: 100%;
      transition: none;
      .content-wrapper {
        padding: 0;
        > div {
          height: 100%;
        }
      }
    }
  }
}

.main-panel {
  transition: width $action-transition-duration $action-transition-timing-function, margin $action-transition-duration $action-transition-timing-function;
  width: calc(100% - #{$sidebar-width-lg});
  min-height: calc(100vh - #{$navbar-height});
  @include display-flex();
  @include flex-direction(column);
  @media (max-width: 991px) {
    margin-left: 0;
    width: 100%;
  }
  &.main-panel-only {
    transition: none;
  }
}

.content-wrapper {
  background: $content-bg;
  padding: 2.75rem 2.25rem;
  width: 100%;
  @include flex-grow(1);
}

.container-scroller {
  overflow: hidden;
}

pre {
  background: color(gray-lighter);
  padding: 15px;
  font-size: 14px;
}

code {
  padding: 5px;
  color: theme-color(danger);
  font-weight: $font-weight-light;
  font-size: $default-font-size;
  border-radius: 4px;
}

.page-header {
  @extend .d-flex;
  @extend .justify-content-between;
  @extend .align-items-center;
  margin: 0 0 1.5rem 0;
  .breadcrumb {
    border: 0;
    margin-bottom: 0;
  }
}
.page-title {
  color: $black;
  font-size: 1.125rem;
  margin-bottom: 0;
  .page-title-icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    border-radius: 4px;
    text-align: center;
    box-shadow: 0px 3px 8.3px 0.7px rgba(163, 93, 255, 0.35);
    i {
      font-size: .9375rem;
      line-height: 36px;
    }
  }
}
.Order-Details
{
  label
  {
    font-weight: 500;
  }
  span
  {
    display: inline-block;
    margin-left: 10px;
  }
  .order-line
  {
    margin-bottom: 10px;
  }
  .shipping-billing
  {
    line-height: 26px;
  }
}
.order-number
{
  margin-bottom: 20px!important;
}
.gap-10
{
  margin-top: 10px!important;
}
.gap-20
{
  margin-top: 20px!important;
}
.gap-30
{
  margin-top: 30px!important;
}
.gap-40
{
  margin-top: 40px!important;
}
.gap-50
{
  margin-top: 50px!important;
}
.order-items
{
  margin-bottom: 20px!important;
  div
  {
    font-weight: 500;
  }
}
.Order-Details  a
{
  color: #343a40!important;
}

.Order-Details .react-datetime-picker__wrapper span {
  margin-left: 1px;
}
select.form-control, .swal2-modal .swal2-content select.swal2-input
{
  color:#111!important;
}
.order-status-label
{
  text-transform: capitalize;
  .badge{font-size: 12px;}
  .on-hold{background: #a660ff;}
  .processing {background: #1c8be3;}
  .pending-payment{ background: #fe98ab;}
  .completed{background: #18cfb3;}
  .cancelled{background: #dadee1;color:#111;}
  .refunded{background:#3e4b5b;}
  .failed{background: #959ca3;}
}

.clickable
{
  cursor: pointer;
}

.order-items div {
  border-top: 0;
  font-weight: 500;
  vertical-align: bottom;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
}

.order-item div {
  vertical-align: middle;
  font-size: 0.875rem;
  line-height: 1;
  white-space: nowrap;
  padding: 0.9375rem;
  vertical-align: top;
  border-top: 1px solid #ebedf2;
  font-family: "Ubuntu", sans-serif;
}
.shipping-billing div
{
  font-family: "Ubuntu", sans-serif;
  font-size: 0.875rem;
  line-height: 1.8;
}
.order-details-page h4
{
    background: #e7e7e7;
    padding: 10px;
    border-radius: 6px;
}
.customer-order-data, .order-items
{
 margin: 0px 2px;
}

.order-item
{
 margin-left: 5px;
}
.shipping-data, .billing-data
{
 margin: 0px 10px;
}
.react-datetime-picker__wrapper {
  border: 1px solid #ebedf2!important;
  border-radius: 3px;
  padding: 6px;
}
.Order-Details .date-field span {
  margin-top: -10px;
}
.outlet-field
{
  margin-bottom: 22px!important;
}
.coming-soon
{
  min-height: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.navbar-brand
{
  color:#ffc222!important;
  font-weight: 900!important;
}